import { CampaignType } from "./Campaign.type";
import { concat, isEmpty, get, cloneDeep } from "lodash";

const initialState = {
  campaigns: [],
  campaignsCount: 0,
  campaignsRuns: [],
  campaignsRunsTotalCount: [],
  sheduleCampaigns: [],
  campaignsDetails: [],
  campaignsDetailsCounts: [],
  campaignsDetailsTotalCounts: 0,
  campaignDashboardData: {},
  campaignInsights: {},
  allcampaignsDetails: [],
  allcampaignsDetailsCounts: [],
  allcampaignsDetailsTotalCounts: 0,
  callHistoryDetails: [],
  callHistoryTotalCounts: 0,
  campaignRunsLive: [],
};

function CampaignReducer(state = initialState, action) {
  switch (action.type) {
    case CampaignType.GET_CAMPAIGN_DATA: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.campaigns, action.payload)
      );

      return {
        ...state,
        campaigns: updatedArry,
      };
    }
    case CampaignType.GET_CAMPAIGN_COUNT_DATA: {
      return {
        ...state,
        campaignsCount: action.payload.totalCount,
        campaignDashboardData: {
          campaignRun: action.payload.campaignRun,
          campaignRunDetails: action.payload.campaignRunDetails,
        },
      };
    }
    case CampaignType.GET_CAMPAIGN_RUNS_DATA: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.campaignsRuns, action.payload)
      );
      return {
        ...state,
        campaignsRuns: updatedArry,
      };
    }
    case CampaignType.GET_CAMPAIGN_RUNS_COUNT_DATA: {
      return {
        ...state,
        campaignsRunsTotalCount: action.payload.totalCount,
      };
    }
    case CampaignType.GET_SCHEDULED_CAMPAIGN_DATA: {
      return {
        ...state,
        sheduleCampaigns: action.payload,
      };
    }
    case CampaignType.GET_CAMPAIGN_INSGHTS: {
      return {
        ...state,
        campaignInsights: action.payload.data,
      };
    }
    case CampaignType.GET_CAMPAIGN_DETAILS_DATA: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.campaignsDetails, action.payload)
      );
      return {
        ...state,
        campaignsDetails: updatedArry,
        campaignsDetailsCounts: action.payload.countsData,
        campaignsDetailsTotalCounts: action.payload.totalCount,
      };
    }
    case CampaignType.GET_ALL_CAMPAIGN_DETAILS_DATA: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.allcampaignsDetails, action.payload)
      );
      return {
        ...state,
        allcampaignsDetails: updatedArry,
        allcampaignsDetailsCounts: action.payload.countsData,
        allcampaignsDetailsTotalCounts: action.payload.totalCount,
      };
    }
    case CampaignType.GET_CALL_HISTORY_DATA: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.callHistoryDetails, action.payload)
      );

      return {
        ...state,
        callHistoryDetails: updatedArry,
      };
    }
    case CampaignType.GET_CALL_HISTORY_COUNT_DATA: {
      return {
        ...state,
        callHistoryDetailsTotalCounts: action.payload.totalCount,
      };
    }
    case CampaignType.GET_CAMPAIGN_RUNS_LIVE_DATA: {
      return {
        ...state,
        campaignRunsLive: action.payload.liveCampaigns.campaignRunDetails || [],
      };
    }

    default:
      return state;
  }
}

export default CampaignReducer;

const setArrayByCase = (array, payload) => {
  if (!isEmpty(get(payload, "searchText")) && get(payload, "isScrolled")) {
    array = [...array, ...payload.data];
    return array;
  } else if (!isEmpty(get(payload, "searchText"))) {
    array = payload.data;
    return array;
  } else if (get(payload, "isScrolled")) {
    array = concat(array, payload.data);
    return array;
  }
  return payload.data;
};
