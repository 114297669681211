export const CampaignType = {
  GET_CAMPAIGN_DATA: "GET_CAMPAIGN_DATA",
  GET_CALL_HISTORY_DATA: "GET_CALL_HISTORY_DATA",
  GET_CALL_HISTORY_COUNT_DATA: "GET_CALL_HISTORY_COUNT_DATA",
  GET_CAMPAIGN_COUNT_DATA: "GET_CAMPAIGN_COUNT_DATA",
  GET_CAMPAIGN_RUNS_DATA: "GET_CAMPAIGN_RUNS_DATA",
  GET_CAMPAIGN_RUNS_COUNT_DATA: "GET_CAMPAIGN_RUNS_COUNT_DATA",
  GET_SCHEDULED_CAMPAIGN_DATA: "GET_SCHEDULED_CAMPAIGN_DATA",
  GET_CAMPAIGN_DETAILS_DATA: "GET_CAMPAIGN_DETAILS_DATA",
  GET_CAMPAIGN_INSGHTS: "GET_CAMPAIGN_INSGHTS",
  GET_ALL_CAMPAIGN_DETAILS_DATA: "GET_ALL_CAMPAIGN_DETAILS_DATA",
  GET_CAMPAIGN_RUNS_LIVE_DATA: "GET_CAMPAIGN_RUNS_LIVE_DATA",
};
