import {
  API,
  BASE_URL_AI,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const getAllUserService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.ADMIN.GET_ALL_USER_LIST}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const rechargeUserService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ADMIN.RECHARGE}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const setPricingService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ADMIN.SET_PRICING}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getApiKeysService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ADMIN.GET_API_KEY}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveMasterPasswordService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ADMIN.MASTER_PASSWORD}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAllUserUsageService = async (days) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.ADMIN.GET_ALL_USER_USAGE_LIST}/${days}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getRechargeHistoryService = async (id, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.ADMIN.RECHARGE_HISTORY}/${id}`,
      { params: { ...obj } }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const pauseAccountService = async (obj, id) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API_V1}${API.V1.ACCESS.PAUSE_ACCOUT}/${id}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const creditAccountService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ADMIN.ACCOUNT_CREDIT}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCreditAccountService = async (obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.ADMIN.ACCOUNT_CREDIT}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteAccountService = async (obj) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API_V1}${API.V1.ACCESS.DELETE_ACCOUNT}`,
      { data: { ...obj } }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getQueueListService = async (obj) => {
  try {
    let res = await axiosClient.get(`${BASE_URL_AI}${API.V1.AI.QUEUE_LIST}`);
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getCallListService = async (obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.AI.CALLS_LIST}?date=${obj}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCallListGatewayService = async (obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.AI.GATEWAY_CALLS_LIST}?date=${obj}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCallsListWithAccountPlusGatewaysService = async (obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.AI.GATEWAY_PLUS_ACCOUNT_CALLS_LIST}?date=${obj}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const increaseQueueListService = async (obj, id) => {
  try {
    let res = await axiosClient.patch(
      `${BASE_URL_AI}${API.V1.AI.UPDATE_LIST}/${id}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
