import React, { useEffect, useState } from "react";
import CommonTable from "../CommonComponents/CommonTable";
import { useDispatch } from "react-redux";
import {
  getCallsListAction,
  getCallsListGatewayAction,
  getCallsListWithAccountPlusGatewaysAction,
  getQueueListAction,
  increaseQueueListAction,
} from "./Store/admin.action";
import CommonModal from "../CommonComponents/CommonModal";
import "../Dashboard/Onboarding.css";
import { useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { renderThumb } from "../CommonComponents/Common";

function TotalCalls() {
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let Stalker = locSearch.get("s-team") || "";
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [date, setDate] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [spin, setSpin] = useState(false);
  const [queUpdate, setQueUpdate] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const init = async () => {
    let res: any = await dispatch(getCallsListAction(date));
    setData(res.data);
  };

  const init1 = async () => {
    let res: any = await dispatch(getCallsListGatewayAction(date1));
    setData1(res.data);
  };
  const init2 = async () => {
    let res: any = await dispatch(
      getCallsListWithAccountPlusGatewaysAction(date2)
    );
    setData2(res.data);
  };

  const refresh = async () => {
    await init();
  };

  const access = [
    "shivam@vodex.ai",
    "sameer@vodex.ai",
    "sandeep@vodex.ai",
    "praveen@vodex.ai",
  ];

  return (
    <div>
      <div className="card">
        <strong className="ml-2 mt-2">Account Wise Calls Data</strong>
        <div className="card-header d-flex">
          <input
            type="text"
            className="form-control w-20"
            onChange={(e) => {
              e.persist();
              setDate(e.target.value);
            }}
          />
          <button className="custom-button-icon" onClick={init}>
            Fetch
          </button>
        </div>
        <div className="card-body">
          <Scrollbars
            style={{ height: "420px", color: "white" }}
            renderThumbVertical={renderThumb}
          >
            <CommonTable
              body={data.map((que: any, idx) => (
                <tr key={`${idx}comm`}>
                  <td>{que?.user_email}</td>
                  <td>{que?.account_name}</td>
                  <td>{que?.account_data?.dialed}</td>
                  <td>{que?.account_data?.connected}</td>
                  <td>{que?.account_data?.error}</td>
                  <td>{que?.account_data?.misc}</td>
                  <td>{que?.account_data?.voicemail}</td>
                  <td>{que?.account_data?.duration}</td>
                  <td>{que?.account_data?.price}</td>
                  <td>{que?.white_label_super_admin}</td>
                  <td>{que?.sid_data?.pikup_rate}</td>
                  <td>{que?.sid_data?.average_call_duration}</td>
                </tr>
              ))}
              headers={[
                "Account Email",
                "Account Name",
                "Total Dialed",
                "Total Connected",
                "Total Errors",
                "Misc",
                " Total Voicemail",
                "Duration",
                "Pricing",
                "Whitelabel",
                "Pickup Rate",
                "Average Call Duration",
              ]}
            />
          </Scrollbars>
        </div>
      </div>
      <div className="card">
        <strong className="ml-2 mt-2"> Gateway Wise Calls Data</strong>

        <div className="card-header d-flex">
          <input
            type="text"
            className="form-control w-20"
            onChange={(e) => {
              e.persist();
              setDate1(e.target.value);
            }}
          />
          <button className="custom-button-icon" onClick={init1}>
            Fetch
          </button>
        </div>
        <div className="card-body">
          <Scrollbars
            style={{ height: "420px", color: "white" }}
            renderThumbVertical={renderThumb}
          >
            <CommonTable
              body={data1.map((que: any, idx) => (
                <tr key={`${idx}comm`}>
                  <td>{que?.user_email}</td>
                  <td>{que?.account_name}</td>
                  <td>{que?.sid}</td>
                  <td>{que?.sid_data?.dialed}</td>
                  <td>{que?.sid_data?.connected}</td>
                  <td>{que?.sid_data?.error}</td>
                  <td>{que?.sid_data?.misc}</td>
                  <td>{que?.sid_data?.voicemail}</td>
                  <td>{que?.sid_data?.duration}</td>
                  <td>{que?.sid_data?.price}</td>
                  <td>{que?.sid_data?.pikup_rate}</td>
                  <td>{que?.sid_data?.average_call_duration}</td>
                </tr>
              ))}
              headers={[
                "Gateway Owner Email",
                "Account Name",
                "Account Sid",
                "Total Dialed",
                "Total Connected",
                "Total Errors",
                "Misc",
                " Total Voicemail",
                "Duration",
                "Pricing",
                "Pickup Rate",
                "Average Call Duration",
              ]}
            />
          </Scrollbars>
        </div>
      </div>

      <div className="card">
        <strong className="ml-2 mt-2">
          {" "}
          Account + Gateway Wise Calls Data
        </strong>

        <div className="card-header d-flex">
          <input
            type="text"
            className="form-control w-20"
            onChange={(e) => {
              e.persist();
              setDate2(e.target.value);
            }}
          />
          <button className="custom-button-icon" onClick={init2}>
            Fetch
          </button>
        </div>
        <div className="card-body">
          <Scrollbars
            style={{ height: "420px", color: "white" }}
            renderThumbVertical={renderThumb}
          >
            <CommonTable
              body={data2.map((que: any, idx) => (
                <tr key={`${idx}comm`}>
                  <td>{que?.user_email}</td>
                  <td>{que?.account_name}</td>
                  <td>{que?.sid}</td>
                  <td>{que?.data?.dialed}</td>
                  <td>{que?.data?.connected}</td>
                  <td>{que?.data?.error}</td>
                  <td>{que?.data?.misc}</td>
                  <td>{que?.data?.voicemail}</td>
                  <td>{que?.data?.duration}</td>
                  <td>{que?.data?.price}</td>
                  <td>{que?.data?.pikup_rate}</td>
                  <td>{que?.data?.average_call_duration}</td>
                </tr>
              ))}
              headers={[
                "Account Email",
                "Account Name",
                "Account Sid",
                "Total Dialed",
                "Total Connected",
                "Total Errors",
                "Misc",
                " Total Voicemail",
                "Duration",
                "Pricing",
                "Pickup Rate",
                "Average Call Duration",
              ]}
            />
          </Scrollbars>
        </div>
      </div>
    </div>
  );
}

export default TotalCalls;
