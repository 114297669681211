import { callSettingType } from "./callSetting.type";

const initialState = {
  callSettings: {},
};

function CallSettingReducer(state = initialState, action) {
  switch (action.type) {
    case callSettingType.GET_CALL_SETTING_DATA: {
      return { ...state, callSettings: action.payload[0] };
    }
    case callSettingType.CLEAR_CALL_SETTING: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

export default CallSettingReducer;
